import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"

import style from "./search.module.css"

export default connectSearchBox(({
  refine,
  currentRefinement,
  isSearchStalled,
  createURL,
  onSearchStateChange,
  ...rest
}) => (
   <form className={style.searchField}>
    <input
      type="text"
      placeholder="Buscar"
      aria-label="Buscar"
      className={style.searchInput}
      value={currentRefinement}
      onChange={e => refine(e.target.value)}
      {...rest}
    />
     {/*<SearchIcon />*/}
  </form>
))
