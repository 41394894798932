import React  from 'react'
import { useState, useEffect, createRef }  from 'react'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch,Hits , connectStateResults , Index} from 'react-instantsearch-dom'

import Input from "./input"
import HitPreview from "./hit-preview"
import "./search.module.css"

const Results = connectStateResults(
  ({ searchState: state, searchResults: res, children }) =>
    state.query && res && res.nbHits > 0 ? children : "No hay resultados"
)

function Search ({language}) {

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )

  const ref = createRef()
  const [focus, setFocus] = useState(false)

  const useClickOutside = (ref, handler, events) => {
    if (!events) events = [`mousedown`, `touchstart`]
    const detectClickOutside = event =>
      !ref.current.contains(event.target) && handler()
    useEffect(() => {
      for (const event of events)
        document.addEventListener(event, detectClickOutside)
      return () => {
        for (const event of events)
          document.removeEventListener(event, detectClickOutside)
      }
    })
  }

  useClickOutside(ref, () => setFocus(false))
  const showHits = focus;

  return(
    <InstantSearch
      searchClient={searchClient}
      indexName="company-manual"
      root={{ Root: 'div', props: { ref } }}
    >
      <Input
       onFocus={() => setFocus(true)}
        focus={String(focus)}
      />
          <Index key="company-manual" indexName="company-manual">
          <div className={`hits-wrapper ${showHits ? 'show' : 'hidden' }`}>

            <Results>
              <Hits hitComponent={HitPreview}/>
              {/*<Hits hitComponent={hitComps[hitComp](() => setFocus(false))} />*/}
            </Results>
          </div>

        </Index>
      </InstantSearch>
   )}

export default Search
